<template>
<div style="display: flex; flex-direction: column; justify-content:center;align-items:center">

  <PetInfo v-if="showpage == 2" :info="petinfo" :uid= "Uid" @edit="modifyInfo"/>
  <PetCreate v-if="showpage == 1" @getinfo="getinfo" :uid= "Uid" :info="petinfo"/>
  <div v-if="showpage == 5"  style="margin-top:50px">
  <van-empty 
  description="Missing or incorrect Tag ID 
  Please scan the QR code again" />
  </div>

<van-loading size="80px"  v-if="showpage == 0" style="margin-top:200px"/>

  </div>
</template>

<script>
import PetCreate from './components/PetCreate.vue'
import PetInfo from './components/PetInfo.vue'
import { doc, getDoc } from "firebase/firestore"; 
import { Loading,Empty } from 'vant';

import { db,idCheck } from "./main.js";


export default {
  name: 'App',
  components: {
    PetCreate,
    PetInfo,
    [Loading.name]:Loading,
    [Empty.name]:Empty,

  },

  setup(){
    
  },
  mounted () {
   
  },
  created(){
    document.title = 'Human Tag';

    let id = window.location.pathname.replace("/","")
    console.log(id); // 第二步
    if(idCheck(id)){
       this.Uid = id;
      this.getinfo()
    }else{
      this.showpage = 5
    }
    
    

  },
  data(){
    return{
      showpage:0,
      petinfo:undefined,
      Uid:""
    }
  },
  methods:{
   async getinfo(isedit){
        this.showpage = 0;
        
        const docSnap =  await getDoc(doc(db, "petInfo", this.Uid));

        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());
          this.petinfo = docSnap.data()
          if(isedit){
            this.showpage = 1;
          }else{
            this.showpage = 2;
          }
          
        } else {
        // docSnap.data() will be undefined in this case
         console.log("No such document!");
         this.showpage = 1;

        }
    },
    modifyInfo(){
      console.log("111");
      this.getinfo(true)
    },
  

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #5072ff;
  padding-top: 0px;
  height: 100%;
  width:100%
}
html,body{
    height: 100%;
    width:100%
  }

/* 添加这段样式后，Primary Button 会变成红色 */
:root:root {
  --van-empty-description-color: #ffffff;
}


</style>
