<template>
    <div class="container">
        <van-image width="25.8rem" height="6.9rem" :src="require('../assets/logo_1.png')" />
    
        <van-cell-group inset style="margin-top:20px">
            <van-image style="margin-top:10px"
            round
            width="8rem"
            height="8rem"
            fit="cover"
            :src="imageUrl"
        />
        <h1  style="margin-top:10px">{{info.Petname}}</h1>
        <van-row  justify="center">
        <van-col span="8">
            <van-row align= "center"> 
                <van-image width="1rem" height="1rem" :src="require('../assets/birth.png')" />
                <p>&nbsp;  {{info.Birthday}}</p>
            </van-row>
        </van-col>
        <van-col>
        <van-row align= "center"> 
                <van-image width="1rem" height="1rem" :src="require('../assets/weight.png')" />
                <p> &nbsp; {{info.Weight}}</p>
        </van-row>
        </van-col>
        </van-row>
       
        <div style= "display: flex; flex-direction: column;">
            <van-button type="primary" class = "button" 
            icon = "phone-o"
            :url="phoneUrl"
            >Call My Family</van-button>
             <!--
            <van-button plain type="primary" class = "button"
            icon = "envelop-o"
            :url="emailUrl"
            >Send Email</van-button>
            -->
        </div>
         
        <div style= "display: flex; flex-direction: column; align-items: start;margin:0 20px">
            <h2>Contact Info</h2>
        </div>
        <van-cell-group inset style="background-color:#f1f1f1; padding: 20px 10px; margin-bottom: 20px">
            <van-col >
                <van-row align="center">
                    <h4><van-icon name="phone-circle-o" color="#1989fa"/>
                     {{info.Phone}}</h4>
                    
                </van-row>

                <van-row align="center" style=" borderColor: '#fff'; margin-top:10px; ">
                    <h4><van-icon name="wap-home-o" color="#1989fa"/>
                     {{info.Address}}</h4>
                </van-row>

                <van-divider style=" borderColor: '#fff' "/>
                <van-col style="text-align：left; display: flex; flex-direction: row; ">
                    <van-icon name="chat-o" color="#1989fa"/>
                    <h4 style="text-align: left; margin-left:10px;">
                        {{info.Note}}
                    </h4>
                </van-col>
            </van-col>
            
        </van-cell-group>
        </van-cell-group>

        <p style="margin-top:20px; color:#fff" @click="onclick">Modify Info</p>
        <h5 style="margin-top:20px; color:#fff">Interested to resale this item in your country or area,<br/>in USA any of his 50 states?
        <br/>write us to : <a href="mailto:decatrelj1@gmail.com">decatrelj1@gmail.com</a>
        <br/> <a href="tel:7876395963">787-639-5963</a>
        </h5>
        <van-dialog v-model:show="showModify" title="Modify Key" show-cancel-button 
        :beforeClose="beforeClose"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        >
            <van-field v-model="modify_key" label="Key"  placeholder="Please Input Modify Key"
            :rules="[{ required: true, message: 'Please input Modify Key' }]"
            />
        </van-dialog>
    </div>
  </template>
  
  <script>
  import 'vant/lib/index.css';
  import { Image as VanImage ,Row,Col,CellGroup,Button,Icon,Divider,Dialog,showToast,Field} from 'vant';
  import {ref as refs,getDownloadURL} from "firebase/storage";
  import {storage,db} from "../main.js";
  import { doc, getDoc } from "firebase/firestore"; 
  import { ref } from 'vue';
  import {md5 } from 'js-md5';

  


  export default {
    name: 'PetInfo',
    props: {
      info: Object,
      uid:String
    },
    components:{
        [VanImage.name]:VanImage,
        [Row.name]:Row,
        [Col.name]:Col,
        [Icon.name]:Icon,
        [CellGroup.name]:CellGroup,
        [Button.name]:Button,
        [Divider.name]:Divider,
        [Dialog.name]:Dialog,
        [Field.name]:Field,
        [showToast.name]:showToast,
        
        
    },
    setup(props, context){
        const modify_key = ref('');
        const beforeClose =   (action) =>
        new Promise( (resolve) => {
            console.log("Action",action)
            if (action == 'confirm') {
                console.log("Action1",action)
             const Uid = props.uid;
             const docSnap =   getDoc(doc(db, "key", Uid));
             docSnap.then ( snap => {
                console.log("docSnap",snap)
                if (snap.exists() && (md5(modify_key.value) == snap.data().Modify_key)) {
                    console.log('suc', );
                    context.emit('edit');
                }else{
                    showToast('Modify Key is Incorrect');
                }
                //console.log("docSnap",snap)
                resolve(true);
             });
            } else {
                // 拦截取消操作
                resolve(true);
            }
        });
        return {
            beforeClose,
            modify_key,
        }
    },
    created(){
         this.setupInfo()
    },
    methods:{
        setupInfo(){
        this.phoneUrl="tel:"+this.info.Phone
        this.emailUrl="mailto:"+this.info.Email
        if(this.info.Image != ""){
            getDownloadURL(refs(storage,this.info.Image)).then((downloadURL) => {
              console.log('File available at', downloadURL);
              this.imageUrl = downloadURL
            });
        }
        
       },
       
       onclick(){
        this.modify_key = ""
        this.showModify = true
       }
    },
    data(){
        return{
            imageUrl:"",
            phoneUrl:"",
            emailUrl:"",
            showModify:false,
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  h4 {
    margin: 0px 0 0;

  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .container{
  background-color: #5072ff;
  color: #000;
  width:100%;
  padding:35px 10px
}
.form_card{
  display: flex;
  flex-direction: column;

}
.button{
    margin:10px 25px
}
  </style>
  