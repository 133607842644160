import { createApp } from 'vue'
import App from './App.vue'
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import {md5 } from 'js-md5';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";



function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}

function idCheck(id){
    if(id.length == 32){
      let start =  id.substr(0, 16);
      let end =  id.substr(16, 16);
      if(end == md5(start).substr(0,16)  ){
        return true;
      }
    }
    return false;


}


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB8te3tP_AQMUXN4GDjEm5GNbQ4L9iKSsk",
    authDomain: "pet-tag-4f8ae.firebaseapp.com",
    projectId: "pet-tag-4f8ae",
    storageBucket: "pet-tag-4f8ae.appspot.com",
    messagingSenderId: "1105731235",
    appId: "1:1105731235:web:2db43ebff9a365312ee916"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
      // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6Lc6scQpAAAAAGhs9dAiuKwN8bGR9XB88uZqWEmm'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
  const storage = getStorage(app);
  const db = getFirestore(app);


  
  

  export {storage,app,db,appCheck,getQueryString,idCheck}

createApp(App).mount('#app')

