<template>
  <div class="container" >
    <p> <br> </p>
    <van-image width="25.8rem" height="6.9rem" :src="require('../assets/logo_1.png')" />
    <h1 style="color:#fff;">Human Tag</h1>
    <h3>
      <p>
        Start to create your personal human tag
      </p>
    </h3>
    <h2 style="color:#fff" >Public Data</h2>
    <h5 style="color:#fff" >This data will visible by scanning the QR Tag</h5>
    <div class="form_card">

    <van-form class=”center“ @submit="onSubmit">
      <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" />

    <van-cell-group inset>
      <van-field
        v-model="petname"
        name="Name"
        label="Name"
        placeholder="Name"
        :rules="[{ required: true, message: 'Please input pet name' }]"
      />
       <!-- 
      <van-field
        v-model="nickname"
        name="Nickname"
        label="Nickname"
        placeholder="Nickname"
      />
      <van-field
        v-model="race"
        name="Race"
        label="Race"
        placeholder="Race"
      />
       -->
      <van-field
        readonly
        clickable
        is-link
        name="datetimePicker"
        v-model="birthday"
        label="Birthday"
        placeholder="Select Pet Birthday"
        @click="showPickdate = true"
      />


      <van-field
        v-model="weight"
        name="Weight"
        label="Weight"
        placeholder="Approximate Weight"
        :rules="[{ required: false, message: 'Please input pet weight' }]"
      />
      <van-field
        v-model="address"
        name="Address"
        label="Address"
        placeholder="Address"
      />
      <van-field
        v-model="phone"
        name="phone"
        label="Tel #"
        type="digit"
        placeholder="Contact Person Tel"
        :rules="[{ required: false, message: 'Please input pet owner phone number' }]"
      />
      <!-- 
      <van-field
        v-model="email"
        name="Email"
        label="Email"
        placeholder="Owner Email"
        :rules="[{ required: false, message: 'Please input pet owner email' }]"
      />
      -->

      <van-field
        v-model="note"
        name="Note"
        label="Note"
        placeholder="Note"
        type="textarea"
        rows="5"
        autosize
        :rules="[{ required: false, message: 'Please write something ' }]"
      />

    </van-cell-group>

      <h2 style="color:#fff" >Modify Key</h2>
      <h5 style="color:#ffff00" >This Key is used to modify the public data,you can input any thing but you must remember it. if not you will can't modify the public data.</h5>
      <van-cell-group inset>
      <van-field
        v-model="modify_key"
        name="Modify key"
        label="Modify key"
        placeholder="Modify key"
        :rules="[{ required: true, message: 'Please input Modify key' }]"
      />
      </van-cell-group>

     


    <div style="margin: 16px;">
    <van-button round block type="primary" native-type="submit" 
    >
      Submit
    </van-button>
    </div>
  </van-form>
  </div>

  <van-popup v-model:show="showPickdate" style="width: 90%;">
    <van-date-picker
          v-model="currentDate"
          title="Select Birthday"
          :max-date="maxDate"
          @confirm="onConfirm"
          @cancel="showPickdate = false"
          confirm-button-text="OK"
          cancel-button-text="Cancel"
        />
  </van-popup>

  <!-- 圆角弹窗（居中） -->
  <van-popup v-model:show="loading_state" round :style="{ padding: '64px' }" >
    <van-loading color="#0094ff" />
  </van-popup>


  </div>
</template>

<script>
import {Image as VanImage, Form, Field, CellGroup,Button,Uploader,Popup,DatePicker,Loading } from 'vant';
import 'vant/lib/index.css';
import { ref } from 'vue';
import {storage,db} from "../main.js";
import {ref as refs, uploadBytesResumable,getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore"; 
import {md5 } from 'js-md5';





export default {
  name: 'PetCreate',
  props: {
    info: Object,
    uid:String
  },
  
  setup() {
    
    var birthday = ref('');
    var date = new Date()
    const currentDate = ref([date.getFullYear()+"", (date.getMonth() + 1)+"", date.getDate()+""]);

    const showPickdate = ref(false);
    const onConfirm = ({ selectedValues }) => {
      birthday.value = selectedValues.join('/');
      showPickdate.value = false;
    };

    return {
      birthday,
      onConfirm,
      showPickdate,
      currentDate,
      maxDate:date,
      minDate: new Date(2000, 0, 1),

    };
  },
  components:{
    [VanImage.name]:VanImage,
    [Form.name]:Form,
    [Field.name]:Field,
    [CellGroup.name]:CellGroup,
    [Button.name]:Button,
    [Uploader.name]:Uploader,
    [DatePicker.name]:DatePicker,
    [Popup.name]:Popup,
    [Loading.name]:Loading
  },
  created(){
    this.initeditInfo()
   },
  data(){
    return{
      petname:"",
      race:"",
      nickname:"",
      address:"",
      weight:"",
      phone:"",
      email:"",
      note:"",
      modify_key:"",
      image_ref:"",
      loading_state:false,
      fileList:[]
    }

  },
  methods: {
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    initeditInfo(){
      if(this.info != undefined){
        this.petname = this.info.Petname;
        this.race = this.info.race;
        this.nickname = this.info.Nickname;
        this.address = this.info.Address;
        this.weight = this.info.Weight;
        this.phone = this.info.Phone;
        this.email = this.info.Email;
        this.note = this.info.Note;
        this.birthday = this.info.Birthday;
        this.image_ref = this.info.Image;
        if(this.info.Image != ""){
                getDownloadURL(refs(storage,this.info.Image)).then((downloadURL) => {
                  console.log('File available at', downloadURL);
                  this.fileList.push({url:downloadURL})
                  this.imageUrl = downloadURL
                });
            }
        }
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}/`;
      } else if (type === 'month') {
        return `${val}/`;
      }
      return val;
    },
    afterRead(filee) {
      var file = filee.file
      // 此时可以自行将文件上传至服务器
      const filepath = 'images/dog/'+new Date().getTime()+file.name+'.jpg'
      const storageRef = refs(storage, filepath);
      const metadata = {
        contentType: 'image/jpeg',
      };
      filee.status = 'uploading';
      filee.message = 'uploading...';

      // 'file' comes from the Blob or File API
      const uploadTask =  uploadBytesResumable(storageRef, file,metadata)
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          console.log('File available at', error);
          filee.status = 'failed';
          filee.message = '上传失败';

          // Handle unsuccessful uploads
        }, 
        () => {
          filee.status = 'done';
          this.image_ref = filepath;
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        },)

    },
    async onSubmit(){
      try {
        this.loading_state = true
        await setDoc(doc(db, "petInfo",this.uid), {
        Petname: this.petname,
        Race:this.race,
        Address:this.address,
        Nickname:this.nickname,
        Birthday: this.birthday,
        Image: this.image_ref,
        Weight: this.weight,
        Phone: this.phone,
        Email: this.email,
        Note:this.note,
        Time: new Date().getTime(),
      });
      await setDoc(doc(db, "key",this.uid), {
        Modify_key:md5(this.modify_key),
      });
      this.$emit('getinfo');
      
      
    } catch (e) {
     
      console.error("Error adding document: ", e);
    }
    this.loading_state = false

      
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.container{
  background-color: #5072ff;
  color: #fff;
  padding-bottom:30px;
}
.form_card{
  display: flex;
  flex-direction: column;

}
.center {
  justify-content: center;
  background-color: #fff;
  width: 95%;
}
</style>
